import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { getColorFromType } from '../../utils/colors';

const CONTAINER_HEIGHT = 100;

const SquareWaveStyle = styled.div`
  height: ${CONTAINER_HEIGHT}px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  background: transparent;
  width: 100%;
`;

const Square = styled.div<{ width: number; height: number; color: string; offset: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  position: absolute;
  left: ${(props) => props.offset}px;
`;

const SquareWave = (props: { className?: string }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const waves = useMemo(() => {
    const bigSquareWidth = windowWidth > 1200 ? 160 : 80;
    const smallSquareWidth = windowWidth > 1200 ? 60 : 40;
    const bigSquareCount = Math.ceil(windowWidth / bigSquareWidth);
    const smallSquareCount = Math.ceil(windowWidth / smallSquareWidth);

    let squares: unknown[] = [];

    for (let i = 0; i < smallSquareCount; i++) {
      const height =
        i % 3 === 0
          ? Math.sin(1 - (Math.PI / 8) * (i / smallSquareCount)) * CONTAINER_HEIGHT
          : i % 3 === 1
          ? (Math.abs(Math.cos((Math.PI / 9) * (i / smallSquareCount)) + 0.5) * CONTAINER_HEIGHT) % 120
          : (Math.abs(Math.tan((Math.PI / 3) * (i / smallSquareCount)) + 0.5) * CONTAINER_HEIGHT) % 120;
      squares = [
        ...squares,
        <Square key={'s' + i} color="#48596f" width={smallSquareWidth} height={height} offset={i * smallSquareWidth} />,
      ];
    }

    for (let i = 0; i < bigSquareCount; i++) {
      const height =
        i % 2 === 0
          ? (Math.sin(Math.PI * (i / bigSquareCount)) * CONTAINER_HEIGHT) / 4
          : Math.sin(Math.PI * (i / bigSquareCount)) * CONTAINER_HEIGHT;
      squares = [
        ...squares,
        <Square
          key={'b' + i}
          color={getColorFromType('primary')}
          width={bigSquareWidth}
          height={height}
          offset={i * bigSquareWidth}
        />,
      ];
    }

    return squares;
  }, [windowWidth]);

  return <SquareWaveStyle className={props.className}>{waves}</SquareWaveStyle>;
};

export default SquareWave;
