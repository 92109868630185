import React from 'react';

interface ISpacer {
  size?: number;
  axis?: 'vertical' | 'horizontal';
  style?: React.HTMLAttributes<HTMLSpanElement>;
  display?: string;
}

const Spacer: React.FC<ISpacer> = ({ size = 16, axis = 'vertical', style = {}, display = 'block' }) => {
  const width = axis === 'vertical' ? 1 : size;
  const height = axis === 'horizontal' ? 1 : size;

  return (
    <span
      style={{
        display,
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
    />
  );
};

export default Spacer;
