export type IColorsTypes = 'primary' | 'secondary' | 'accent';

export const getColorFromType = (type: IColorsTypes) => {
  switch (type) {
    case 'primary':
      return 'var(--primary-color)';
    case 'secondary':
      return 'var(--secondary-color)';
    case 'accent':
      return 'var(--accent-color)';
    default:
      return 'var(--primary-color)';
  }
};
