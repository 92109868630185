import styled from 'styled-components';
import SquareWave from '../../shared/SquareWave';

export const MainContainer = styled.div`
  background-image: radial-gradient(circle, var(--primary-color), var(--secondary-color));
  height: 85vh;
  padding: 0 15rem;
  position: relative;

  @media (max-width: 1600px) {
    padding: 0 7.5rem;
  }

  @media (max-width: 1024px) {
    height: 100vh;
    padding: 0 2.5rem;
  }
`;

export const AnimationContainer = styled.div`
  height: 480px;
  @media (max-width: 1024px) {
    height: 320px;
  }
`;

export const AboutMeContainer = styled.div`
  position: relative;
  background: #2f3e52;

  padding: 5rem 15rem;

  @media (max-width: 1600px) {
    padding: 5rem 7.5rem;
  }

  @media (max-width: 1024px) {
    padding: 2.5rem 2.5rem;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--secondary-color);
  color: var(--text-color);
  padding: 2rem 1.5rem;
  border-radius: 2px;
  box-shadow: 0px 0px 10px var(--secondary-color);

  .inner-profile-container {
    max-width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .icons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;

    svg {
      color: var(--accent-color);
      min-height: 1.6rem;
      min-width: 1.6rem;
      cursor: pointer;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
`;

export const SectionSubTitle = styled.h2`
  font-size: 1.375rem;
  color: var(--text-color);
  font-weight: 500;
`;

export const SectionText = styled.p`
  font-size: 1.175rem;
  color: #bcc2cc;
  font-weight: 400;
`;

export const AccentText = styled.h1`
  text-transform: uppercase;
  color: var(--accent-color);
  font-size: 1.5rem;
  font-weight: 500;
`;

export const MainText = styled.h1`
  text-transform: capitalize;
  color: var(--text-color);
  font-size: 2.25rem;
  font-weight: 600;
`;

export const SubText = styled.h2`
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
  max-width: 35rem;
`;

export const ProfileImage = styled.img`
  display: block;
  margin: 0 auto;
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  padding: 0.25rem;
`;

export const SectionTransition = styled(SquareWave)`
  position: absolute !important;
  top: -100px;
  left: 0;
`;

export const BottomSectionTransition = styled(SquareWave)`
  transform: rotateX(180deg);
  position: absolute !important;
  bottom: -100px;
  left: 0;
`;

export const Menu = styled.ul`
  position: absolute;
  top: 2.5em;
  right: 0;
  display: flex;
  list-style-type: none;
  padding: inherit;

  li {
    margin-left: 2rem;
    color: var(--text-color);
    font-size: 1.175rem;
    font-weight: 500;
    cursor: pointer;
  }

  li:hover {
    &:after {
      content: '';
      display: block;
      width: 3em;
      height: 2px;
      background: var(--accent-color);
      border-radius: 6px;
    }
  }

  li:first-child {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  color: var(--text-color);
  font-size: 0.75rem;
  font-weight: 300;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  margin: 8px 32px 16px 0;

  svg {
    margin-bottom: 0.25rem;
    font-size: 3.25rem;
  }

  &:hover {
    color: var(--accent-color);
  }
`;

export const TechStackTitle = styled.h2`
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 500;
  margin-top: 16px;
  text-transform: uppercase;
`;
