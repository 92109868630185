// create a router component using react-router-dom library for react
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../pages/Home';

const Routes = () => (
  <Switch>
    <Route path="/" exact>
      <HomePage />
    </Route>
  </Switch>
);

export default Routes;
