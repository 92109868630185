import React from 'react';
import styled from 'styled-components';
import { getColorFromType, IColorsTypes } from '../../utils/colors';

interface IItemDot {
  type?: IColorsTypes;
  size?: number;
}

const ItemDotStyle = styled.div<{ color: string; size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border: double 6px ${(props) => props.color};
  background: ${(props) => props.color};
  background-clip: padding-box;
`;

const ItemDot = (props: IItemDot) => {
  const { type = 'accent', size = 16 } = props;

  return <ItemDotStyle color={getColorFromType(type)} size={size} />;
};

export default ItemDot;
