import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { useRive, LoopEvent } from 'rive-react';
import { FiGithub, FiLinkedin, FiMail } from 'react-icons/fi';
import {
  SiJavascript,
  SiTypescript,
  SiJava,
  SiCplusplus,
  SiPhp,
  SiDart,
  SiMysql,
  SiMongodb,
  SiPostgresql,
  SiMicrosoftsqlserver,
  SiReact,
  SiAngular,
  SiFlutter,
  SiNodeDotJs,
  SiSpring,
  SiApollographql,
  SiGraphql,
  SiAdonisjs,
  SiRedux,
  SiHtml5,
  SiCss3,
} from 'react-icons/si';
import * as S from './styles';
import { ReactComponent as CLangIcon } from '../../assets/c-icon.svg';

// @ts-ignore
import hoverboard from '../../assets/hoverboard.riv';
// @ts-ignore
import profileSrc from '../../assets/profile.jpeg';
import ItemDot from '../../shared/ItemDot';
import Spacer from '../../shared/Spacer';

const HomePage = () => {
  const [needsReset, setNeedsReset] = useState(false);
  const aboutMeSection = useRef<HTMLDivElement>(null);
  const techStackSection = useRef<HTMLDivElement>(null);
  const { RiveComponent, rive } = useRive({
    src: hoverboard,
    autoplay: true,
    animations: 'Animation1',
    onLoop: (l) => {
      if ((l.data as LoopEvent)?.animation === 'Animation2') {
        setNeedsReset(true);
      }
    },
  });

  useEffect(() => {
    if (needsReset) {
      rive?.reset({ animations: 'Animation1', autoplay: true });
      setNeedsReset(false);
    }
  }, [needsReset, rive]);

  const scrollSmooth = (section: React.RefObject<HTMLDivElement>) => {
    section.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Layout hasSider={false} style={{ minHeight: '100vh' }}>
      <Content style={{ background: 'var(--secondary-color)' }}>
        <S.MainContainer>
          <S.Menu>
            <li onClick={() => scrollSmooth(aboutMeSection)}>About Me</li>
            <li onClick={() => scrollSmooth(techStackSection)}>Tech Stack</li>
            <li>Contact Me</li>
          </S.Menu>
          <Row style={{ height: '100%' }} align="middle">
            <Col xs={24} md={12} lg={16}>
              <S.AccentText>Hi, I am</S.AccentText>
              <S.MainText>Gabriel Rezende</S.MainText>
              <S.SubText>
                A developer focused on web and mobile applications, and this is my personal portifolio
              </S.SubText>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <S.AnimationContainer>
                <RiveComponent onMouseEnter={() => rive?.play('Animation2')} />
              </S.AnimationContainer>
            </Col>
          </Row>
        </S.MainContainer>
        <S.AboutMeContainer ref={aboutMeSection}>
          <S.SectionTransition />
          <Row style={{ height: '100%' }} gutter={[64, 48]}>
            <Col xs={24} md={12} lg={10} xl={8} xxl={6}>
              <S.ProfileContainer>
                <div className="inner-profile-container">
                  <S.ProfileImage src={profileSrc} />
                  <Spacer size={32} />
                  <h3>Gabriel Rezende</h3>
                  <h3>Espírito Santo - Brazil</h3>
                  <h3>Computer Engineer</h3>
                  <Spacer />
                  <div className="icons-container">
                    <FiMail />
                    <FiGithub />
                    <FiLinkedin />
                  </div>
                </div>
              </S.ProfileContainer>
            </Col>
            <Col xs={24} md={12} lg={14} xl={16} xxl={18}>
              <Row align="middle">
                <ItemDot />
                <Spacer axis="horizontal" />
                <S.SectionTitle>About me</S.SectionTitle>
              </Row>
              <Spacer size={8} />
              <S.SectionSubTitle>A little about my story on the developer career</S.SectionSubTitle>
              <Spacer size={8} />
              <S.SectionText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere dignissim felis ut vehicula.
                Proin velit dolor, pulvinar ac consectetur vitae, pellentesque congue felis. Nunc consectetur mattis
                commodo. Morbi urna sem, finibus ac nulla vitae, bibendum cursus lorem. Fusce pulvinar ut risus vitae
                efficitur. Morbi sodales purus commodo justo sollicitudin, a finibus tellus luctus. Etiam elementum
                augue nec ex viverra mollis. Fusce finibus molestie neque in convallis. Donec finibus tellus a erat
                placerat, sed vestibulum orci mollis. Ut eget quam lacinia neque convallis aliquam at ut sapien. Donec
                consectetur sem dui, at sagittis sem pellentesque e
              </S.SectionText>
            </Col>
            <Col span={24} ref={techStackSection}>
              <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                  <Row align="middle">
                    <ItemDot />
                    <Spacer axis="horizontal" />
                    <S.SectionTitle>Tech Stack</S.SectionTitle>
                  </Row>
                </Col>
                <Col span={24}>
                  <S.TechStackTitle>Languages</S.TechStackTitle>
                  <Row>
                    <S.IconContainer>
                      Typescript <SiTypescript />
                    </S.IconContainer>
                    <S.IconContainer>
                      Javascript <SiJavascript />
                    </S.IconContainer>
                    <S.IconContainer>
                      Java <SiJava />
                    </S.IconContainer>
                    <S.IconContainer>
                      C <CLangIcon />
                    </S.IconContainer>
                    <S.IconContainer>
                      C++ <SiCplusplus />
                    </S.IconContainer>
                    <S.IconContainer>
                      Dart <SiDart />
                    </S.IconContainer>
                    <S.IconContainer>
                      PHP <SiPhp />
                    </S.IconContainer>
                  </Row>
                </Col>
                <Col span={24}>
                  <S.TechStackTitle>Databases</S.TechStackTitle>
                  <Row>
                    <S.IconContainer>
                      MySQL <SiMysql />
                    </S.IconContainer>
                    <S.IconContainer>
                      MongoDB <SiMongodb />
                    </S.IconContainer>
                    <S.IconContainer>
                      PostgreSQL <SiPostgresql />
                    </S.IconContainer>
                    <S.IconContainer>
                      Microsoft SQL Server <SiMicrosoftsqlserver />
                    </S.IconContainer>
                  </Row>
                </Col>
                <Col span={24}>
                  <S.TechStackTitle>Front-end</S.TechStackTitle>
                  <Row>
                    <S.IconContainer>
                      HTML <SiHtml5 />
                    </S.IconContainer>
                    <S.IconContainer>
                      React <SiReact />
                    </S.IconContainer>
                    <S.IconContainer>
                      CSS <SiCss3 />
                    </S.IconContainer>
                    <S.IconContainer>
                      React Native <SiReact />
                    </S.IconContainer>
                    <S.IconContainer>
                      Angular <SiAngular />
                    </S.IconContainer>
                    <S.IconContainer>
                      Flutter <SiFlutter />
                    </S.IconContainer>
                    <S.IconContainer>
                      Redux <SiRedux />
                    </S.IconContainer>
                  </Row>
                </Col>

                <Col span={24}>
                  <S.TechStackTitle>Back-end</S.TechStackTitle>
                  <Row>
                    <S.IconContainer>
                      Node.js <SiNodeDotJs />
                    </S.IconContainer>
                    <S.IconContainer>
                      Spring <SiSpring />
                    </S.IconContainer>
                    <S.IconContainer>
                      Apollo Server
                      <SiApollographql />
                    </S.IconContainer>
                    <S.IconContainer>
                      GraphQL <SiGraphql />
                    </S.IconContainer>
                    <S.IconContainer>
                      AdonisJS <SiAdonisjs />
                    </S.IconContainer>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <S.BottomSectionTransition />
        </S.AboutMeContainer>
        <div style={{ height: 800 }} />
      </Content>
    </Layout>
  );
};

export default HomePage;
